function parse(res) {
    try {
        return JSON.parse(res)
    } catch (error) {
        return res
    }
}

class ApiClass {
    constructor(uri) {
        this.uri = uri == undefined
            ? "https://solpad-netapi.azurewebsites.net/api/"//"https://solpad-netapi-dev.azurewebsites.net/api/"
            : uri
        this.methods = {}

        this.blockchain = {
            status: this.endfuncs("blockchain/status"),
            // rpc: "https://solpad-rpc-api.azurewebsites.net/rpc",
            // bridge: "https://solpad-rpc-api.azurewebsites.net/bridge",
          // https://solana-mainnet.g.alchemy.com/v2/FLBT2V7z5Vy1IZuCCkruoTF84zE4wetX
            rpc: "https://solana-mainnet.g.alchemy.com/v2/FLBT2V7z5Vy1IZuCCkruoTF84zE4wetX",
            bridge: "https://solana-mainnet.g.alchemy.com/v2/FLBT2V7z5Vy1IZuCCkruoTF84zE4wetX",

        }
        this.fairsale = {
            ...this.endfuncs("fairsale"),
            list: this.endfuncs("fairsale/list")
        }
        this.presale = {
            ...this.endfuncs("presale"),
            list: this.endfuncs("presale/list")
        }
        this.private = {
            ...this.endfuncs("private"),
            list: this.endfuncs("private/list")
        }
        this.launch = {
            list: this.endfuncs("launch/list"),
            trend: this.endfuncs("launch/trend"),
        }

        this.event = {
            log: {
                buy: this.endfuncs("event/log/buy"),
                click: this.endfuncs("event/log/click")
            }
        }
        /// https://solpad-analytics-api.azurewebsites.net/api/
        this.lock = {
            solana: {
                token: {
                    ...this.endfuncs("lock/solana/token"),
                    list: this.endfuncs("lock/solana/token/list")
                },
                liquidity: {
                    ...this.endfuncs("lock/solana/liquidity"),
                    list: this.endfuncs("lock/solana/liquidity/list")
                }
            },
            tron: {
                token: {
                    ...this.endfuncs("lock/tron/token"),
                    list: this.endfuncs("lock/tron/token/list")
                },
                liquidity: {
                    ...this.endfuncs("lock/tron/liquidity"),
                    list: this.endfuncs("lock/tron/liquidity/list")
                }
            },
            "evm-97": {
                token: {
                    ...this.endfuncs("lock/bsc/token"),
                    list: this.endfuncs("lock/bsc/token/list")
                },
                liquidity: {
                    ...this.endfuncs("lock/bsc/liquidity"),
                    list: this.endfuncs("lock/bsc/liquidity/list")
                }
            },
            "evm-56": {
                token: {
                    ...this.endfuncs("lock/bsc/token"),
                    list: this.endfuncs("lock/bsc/token/list")
                },
                liquidity: {
                    ...this.endfuncs("lock/bsc/liquidity"),
                    list: this.endfuncs("lock/bsc/liquidity/list")
                }
            },
        }

        this.sale = {
            metadata: this.endfuncs("sale/metadata"),
            status: this.endfuncs("sale/status")
        }
        this.refs = {
            ...this.endfuncs("refs")
        }
        this.token = {
            ...this.endfuncs("token"),
            spl: this.endfuncs("token/spl"),
            edit: this.endfuncs("token/edit"),
        }

    }
    use(url) {
        return new ApiClass(url)
    }
    endfuncs(endpoint) {
        return {
            post: async (value) => {
                return await this.write(endpoint, value)
            },
            get: async (value) => {
                return await this.get(endpoint, value)
            }
        }
    }
    async write(endpoint, data) {
        //  console.log("post "+ endpoint)
        try {
            const api = await fetch(this.uri + endpoint,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(data),
                }).then((e) =>
                    e.json()
                    //  


                )
       //    if (e.status == 202) return api
           // console.log({ data, api })

            try {
                if (api.success != undefined && api.res != undefined) {
                    if (api.success) {
                        return api.res;
                    } else {
                        throw "no api";
                    }
                } else {
                    return api;
                }
            } catch (error) {
                if (error == "no api")
                    throw error
                return ""
            }

        } catch (error) {
            console.log(`write  ${endpoint}: ${error}`)
        }

    }
    async get(endpoint, data) {
        // console.log(this.uri + endpoint)///const result = await fetch(this.storage + endpoint + (data.length > 0 ? "/"+ (data.includes("?")? data.concat("api_key=799cd6e5-2128-4b6c-8c50-3862ffcf883f") : data.concat("?api_key=799cd6e5-2128-4b6c-8c50-3862ffcf883f")) + "" : "?api_key=799cd6e5-2128-4b6c-8c50-3862ffcf883f"),
        try {
            const res = await fetch(this.uri + endpoint + (data != undefined ? "/" + data : ""),
                {
                    method: "GET",

                },).then((e) => e.text())
            const api = parse(res)
            // console.log({ endpoint, data, api })
            if (api.success != undefined && api.res != undefined) {
                if (api.success) {
                    return api.res;
                } else {
                    throw "no api";
                }
            } else {
                return api;
            }

            /* if (result.status != undefined) {
                 if (result.status == 0) {
                     return undefined
                 } else {
                     return result
                 }
             } else {
                 return result
             }*/
        } catch (error) {
            console.log(`get ${endpoint}: ${error}`)
        }
    }

}
const Api = new ApiClass()
export default Api