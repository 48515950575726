import React from "react";
import "../styles/styles.scss";
import "../styles/styles-mobile.scss";
import TrendingComponent from "../components/trending/trending";
import Navbar from "../components/navbar/Navbar";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import HomePage from "../pages/home/HomePage";

import CreateToken from "../pages/token-builder/create-token";
import EditToken from "../pages/token-builder/metadata-tool.js";
import BurnToken from "../pages/token-builder/burn-tool.js";

import CreatePresale from "../pages/launchpads/create/create-presale.js";
import CreatePrivateSale from "../pages/launchpads/create/create-private-sale.js";
import CreateFairLaunch from "../pages/launchpads/create/create-fair-launch.js";
import SalesList from "../pages/launchpads/list.js";
import SalesEdit from "../pages/launchpads/edit/edit.js";
import SaleDetail from "../pages/launchpads/view.js";

import env from "../hooks/data/Config.js";

//import EditFairLaunch from "../pages/launchpads/fair-launch/edit-fair-launch.js";
import Staking from "../pages/staking/staking";
//import EditPrivateSale from "../pages/private-sale/edit-private-sale.js";

import LockList from "../pages/lock/lock-list.js";
import CreateLock from "../pages/lock/create-lock.js";
import Utk from "../pages/lock/components/utklocklist.js";
import Ulp from "../pages/lock/components/ulplocklist.js";
import Lll from "../pages/lock/components/liquiditylocklist.js";
import Tll from "../pages/lock/components/tokenlocklist.js";

import salev3 from "../abis/PreSale/PreSalev3.json";
import privv2 from "../abis/Private/privateAbi.json";
import AddLp from "../pages/token-builder/addlp.js";

const RoutesSolpad = ({
  WButton,
  price,
  selected,
  openSettins,
  rpcs,
  select,
  setPriority,
  setCustom,
  priceSol,
}) => {
  return (

    <Router>
      <TrendingComponent price={price} />
      <Navbar
        selected={selected}
        openSettins={openSettins}
        rpcs={rpcs}
        select={select}
        WButton={WButton}
        setPriority={setPriority}
        setCustom={setCustom}
      />
      <div className="container-launch">
        <div className="content-launch">
          <Routes>
            <Route path="/" element={<HomePage />} />
            {/*lists */}
            <Route
              exact
              path="/launchpads"
              element={<SalesList type={0} price={priceSol} />}
            />
            <Route
              path="/privatesales"
              element={<SalesList type={1} price={priceSol} />}
            />

            {/*creates */}
            <Route path="/create/presale" element={<CreatePresale />} />
            <Route path="/create/fairlaunch" element={<CreateFairLaunch />} />
            <Route
              path="/create/privatesales"
              element={<CreatePrivateSale />}
            />
            {/*edits */}
            <Route
              path="/:net/edit/presale/:param"
              element={<SalesEdit type={0} contract={env.preSaleContractv2} />}
            />
            <Route
              path="/:net/edit/fairlaunch/:param"
              element={<SalesEdit type={1} contract={env.fairSaleContract} />}
            />
            <Route
              path="/:net/edit/privatesales/:param"
              element={<SalesEdit type={2} contract={env.priSaleContract} />}
            />
            {/*sales */}
            <Route
              path="/:net/presale/:param"
              element={
                <SaleDetail
                  ct={env.preSaleContractv2}
                  abi={salev3}
                  price={priceSol}
                />
              }
            />
            <Route
              path="/:net/privatesale/:param"
              element={
                <SaleDetail
                  ct={env.priSaleContract}
                  abi={privv2}
                  price={priceSol}
                />
              }
            />
            <Route
              path="/:net/fairlaunch/:param"
              element={
                <SaleDetail
                  ct={env.fairSaleContract}
                  abi={salev3}
                  price={priceSol}
                />
              }
            />

            {/*tokenTools */}
            <Route path="/create/lp" element={<AddLp />} />
            <Route path="/create/token" element={<CreateToken />} />
            <Route path="/edit/token" element={<EditToken />} />
            <Route path="/burn/token" element={<BurnToken />} />
            {/*lockTools */}
            <Route
              path="/create/lock/token"
              element={<CreateLock type={0} />}
            />
            <Route
              path="/create/lock/liquidity"
              element={<CreateLock type={1} />}
            />

            <Route
              path="/lock/token/user/list"
              element={
                <Utk element={<LockList view={0} type={0} my={true} />} />
              }
            />
            <Route
              path="/lock/liquidity/user/list"
              element={
                <Ulp element={<LockList view={0} type={1} my={true} />} />
              }
            />
            <Route
              path="/lock/token/list"
              element={
                <Tll element={<LockList view={0} type={0} my={false} />} />
              }
            />
            <Route
              path="/lock/token/data"
              element={<LockList view={2} type={0} my={false} />}
            />
            <Route
              path="/lock/token/update"
              element={<LockList view={3} type={0} my={false} />}
            />
            <Route
              path="/lock/token/transfer"
              element={<LockList view={4} type={0} my={false} />}
            />

            <Route
              path="/lock/liquidity/list"
              element={
                <Lll element={<LockList view={0} type={1} my={false} />} />
              }
            />
            <Route
              path="/lock/liquidity/data"
              element={<LockList view={2} type={1} my={false} />}
            />
            <Route
              path="/lock/liquidity/update"
              element={<LockList view={3} type={1} my={false} />}
            />
            <Route
              path="/lock/liquidity/transfer"
              element={<LockList view={4} type={1} my={false} />}
            />

            <Route path="/revenue" element={<Staking />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
};

export default RoutesSolpad;
