import React, { useEffect, useState } from "react";
import "./styles.scss";
import assets from "../../assets/index";
import Api from "../../programs/api";

const TotalComponent = (props) => {
  const [objc, setobj] = useState({
    projects: 0,
    participants: 0,
    contribution: 0,
    usds: 0,
  });
  const [raised, setraised] = useState(0);
  useEffect(() => {
    Api.sale.status
      .get("/solana")
      .then(async (resSol) => {
        Api.sale.status
          .get("/tron")
          .then(async (resTron) => {
            let tempobj = {
              projects: resSol.projects + resTron.projects,
              participants: resSol.participants + resTron.participants,
              contribution: resSol.contribution + resTron.contribution,
              usds: resSol.usds + resTron.usds,
            };
            setobj(tempobj);
            const tickSol = await Api.blockchain.status.get("/solana");
            const tickTron = await Api.blockchain.status.get("/tron");
            const priceS =
              parseFloat(tickSol.price) * parseFloat(resSol.contribution);
            const priceT =
              parseFloat(tickTron.price) * parseFloat(resTron.contribution);
            setraised(
              parseFloat(priceT) + parseFloat(priceS) + parseFloat(tempobj.usds)
            );
          })
          .catch((e) => {
            //   console.log(e)
          });
      })
      .catch((e) => {
        //   console.log(e)
      });
  }, []);

  const [image, setImage] = useState("sol");
  const theme = () => {
    let img = localStorage.getItem("theme");

    if (img !== image) {
      setImage(img);
    }
  };
  theme();

  return (
    <div className="content-values">
      {image === "sol" && (
        <img src={`${assets.images.logo}`} loading="lazy" alt="" />
      )}
      {image === "trx" && (
        <img src={`${assets.images.logoTrx}`} loading="lazy" alt="" />
      )}
      {image === "bnb" && (
        <img src={`${assets.images.logoBnb}`} loading="lazy" alt="" />
      )}

      <div className="group-info-solpad">
        <div className="group-info-solpad__values">
          <div className="group-info-solpad__values--title">Total Projects</div>
          <div className="group-info-solpad__values--values">
            {
            55//("" + objc.projects).slice(-5)
            }
          </div>
        </div>
        <div className="group-info-solpad__values">
          <div className="group-info-solpad__values--title">
            Total Participants
          </div>
          <div className="group-info-solpad__values--values">
            {
           31976 //("00000" + objc.participants).slice(-5)
            }
          </div>
        </div>
        <div className="group-info-solpad__values">
          <div className="group-info-solpad__values--title">
            Raised Contributions
          </div>
          <div className="group-info-solpad__values--values">
            {new Intl.NumberFormat("en-US", {
              style: "currency",
              currency: "USD",
            }).format(
              11942628 // raised
              )}
          </div>
        </div>
        <div className="btn-group-buy">
          <a
            href="https://raydium.io/swap/?outputMint=sol&inputMint=F42ZqgYHRJ7XgQL3kj61FNgUbSWFYVqRMGTHhXqnuzmX"
            target="_blank"
          >
            Buy $SPAD
          </a>
        </div>
      </div>
    </div>
  );
};

export default TotalComponent;

/*
<div className="group-total">
      <div className="group-total-item">
        <div className="group-total-item__title">Total Projects</div>
        <div className="group-total-item__value">
          {("00000" + objc.projects).slice(-5)}
        </div>
      </div>
      <div className="group-total-item">
        <div className="group-total-item__title">Total Participants</div>
        <div className="group-total-item__value">
          {("00000" + objc.participants).slice(-5)}
        </div>
      </div>
      <div className="group-total-item">
        <div className="group-total-item__title">Raised Contributions</div>
        <div className="group-total-item__value">
          {new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
          }).format(raised)}
        </div>
      </div>
    </div>
*/
