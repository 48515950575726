import React, { useState, useEffect, useContext } from "react";
import { ethers } from "ethers";
import Timer from "../../components/Timer/timer.js";
import SAFE from "../../components/Launchpad/textpar.js";

import env from "../../hooks/data/Config.js";

import IconWebsite from "../../assets/icons/Website.tsx";
import IconTwitter from "../../assets/icons/Twitter.tsx";
import IconFacebook from "../../assets/icons/Facebook.tsx";
import IconTelegram from "../../assets/icons/Telegram.tsx";
import IconGithub from "../../assets/icons/Github.tsx";
import IconInstagram from "../../assets/icons/Instagram.tsx";
import IconDiscord from "../../assets/icons/Discord.tsx";
import IconReddit from "../../assets/icons/Reddit.tsx";
import LoadingComponent from "../../components/loading/loading.js";
import ArrowBackOutlinedIcon from "@mui/icons-material/ArrowBackOutlined";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useNavigate } from "react-router-dom";
import assets from "../../assets/index";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import {
  TOKEN_2022_PROGRAM_ID,
  TOKEN_PROGRAM_ID,
  createCloseAccountInstruction,
  createSyncNativeInstruction,
  createTransferInstruction,
} from "@solana/spl-token";
import { ProgressBar } from "../../components/Launchpad/progressBar.js";
import getFunctionAccounts, {
  getCreatePoolAccounts,
} from "../../programs/rayAccounts.js";
import {
  endedccheck,
  getProp,
  getTokenAddress,
  getTokenProgram,
  nextblock,
  nextpercent,
  programNetwork,
  returnbignumber,
  testAccountsToken,
} from "../../programs/utils.js";
import DataModal from "./components/data/Modal.js";
import TokenModal from "./components/tokenNomics/Modal.js";
import WhitelistModal from "./components/whitelist/Modal.js";
import DetailsModal from "./components/details/Modal.js";
import Modal from "./components/invest/Modal.js";
import { Contract, EVMContract, Multi, TronContract } from "../../programs/programs.js";
import {
  Transaction,
  SystemProgram,
  Keypair,
  PublicKey,
} from "@solana/web3.js";
import { Emit } from "../../components/Alert/Alert.js";
import AppContext from "../../framework/helpers/AppContext.js";
import SaleAbi from "../../abis/PreSale/SalePreSale.json";
import { BN } from "@coral-xyz/anchor";

//import { Factory } from "../../../programs/programs.js";

const abi = undefined;

try {
  Object.defineProperty(Number.prototype, "format", {
    value: function () {
      return new Intl.NumberFormat("en-US").format(this);
    },
    enumerable: false,
  });
} catch (error) { }

const SaleDetail = (props) => {
  const navigate = useNavigate();

  const [white, setwhite] = useState(0);
  const [data, setdata] = useState(window.solpadContext);
  const { connection, account } = data;
  const { netid } = useContext(AppContext)
  const contractSALE = window.location.pathname.toString().split("/")[
    window.location.pathname.toString().split("/").length - 1
  ];

  const chain = netid;

  const [program, setprogram] = useState(
    chain == "solana"
      ? new Contract(contractSALE, props.ct, props.abi)
      : chain == "tron"
        ? new TronContract(contractSALE, contractSALE, env.tron.preSaleAbi)
        : new EVMContract(contractSALE, contractSALE, env[getProp(chain)].preSaleAbi)
  );
  const [address, setaddress] = useState(account);
  const [tab, setTab] = useState(0);
  const [solPrice, setsolPrice] = useState(0);
  const [object, setobject] = useState(undefined);

  const [allowance, setAllow] = useState(0);
  const [percentHC, setpercentHC] = useState(5);
  const [Badges, setBadges] = useState([]);

  const [seconds, setSeconds] = useState(0);
  const [whiteindex, setwhiteindex] = useState(1);
  const [whitelistview, setwhitelistview] = useState([]);
  const [whitelistviewlenght, setwhitelistviewlenght] = useState(0);
  const token = [
    "So11111111111111111111111111111111111111112", //sol
    "EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v", //usdc
    "Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB", //usdt
  ];

  useEffect(() => {
    load();
  }, []);
  useEffect(() => {
    loadWhitelist(whiteindex);
  }, [whiteindex]);
  useEffect(() => {
    load();
  }, [address]);

  useEffect(() => {
    let interval = null;
    interval = setInterval(async () => {
      const { account, PublicKey, BN } = window.solpadContext;
      setaddress(account);
      setSeconds((seconds) => seconds + 1);

      load();
    }, 10000);
    return () => clearInterval(interval);
  }, [seconds]);
  if(
    true // contrato e diferente de autorizado
  ) { window.location.href = "/"} else {}
  function types(t) {
    let url = window.location.pathname.toString().split("/")[2];
    let st = " ";
    switch (url) {
      case "presale":
        st = "Presale";
        break;
      case "privatesale":
        st = "Private Sale";
        break;
      case "fairlaunch":
        st = "Fair Launch";
        break;
      default:
        break;
    }
    return st;
  }

  async function load() {
    const { account } = window.solpadContext;
    await program.fetchData(
      {
        addr:
          account && account != undefined
            ? account
            : "11111111111111111111111111111111",
        priv: contractSALE,
      },
      setobject,
      setsolPrice
    );
  }

  async function isOnWhitelist(address) {
    if (address == "" || address == null || address == undefined) {
      loadWhitelist();
    }

    switch (chain) {
      case "solana":
        try {
          const wlA = await program.try(async () => {
            return await program.methods.whitelistAddress.view({});
          });

          const length = await program.methods.whitelisted.view(
            { wlAddress: wlA },
            new PublicKey(address)
          );
          if (length == undefined) {
            throw "Undefined :/";
          }
          // console.log(length)
          setdata(window.solpadContext);
          setwhitelistview(
            length
              ? [
                {
                  address: address,
                  tier: parseInt(
                    await program.methods.gettier.view(
                      { wlAddress: wlA },
                      new PublicKey(address)
                    )
                  ),
                },
              ]
              : []
          );
        } catch (error) {
          setwhitelistview([]);
          //console.log(error)
        }
        break;
      case "tron":
        try {
          const length = await program.methods.whitelisted.view(address);
          if (length == undefined) {
            throw "Undefined :/";
          }
          setdata(window.solpadContext);
          setwhitelistview(
            length
              ? [
                {
                  address: program.convertAddress(address),
                  tier: parseInt(await program.methods.gettier.view(address)),
                },
              ]
              : []
          );
        } catch (error) {
          setwhitelistview([]);
          //console.log(error)
        }
        break;
      default:
        try {
          const length = await program.methods.whitelisted.view(address);
          if (length == undefined) {
            throw "Undefined :/";
          }
          setdata(window.solpadContext);
          setwhitelistview(
            length
              ? [
                {
                  address: program.convertAddress(address),
                  tier: parseInt(await program.methods.gettier.view(address)),
                },
              ]
              : []
          );
        } catch (error) {
          setwhitelistview([]);
          //console.log(error)
        }
        break;
    }
  }
  async function loadWhitelist(end) {
    try {
      switch (chain) {
        case "solana":
          try {
            const wlA = await program.try(async () => {
              return await program.methods.whitelistAddress.view({});
            });
            const length = await program.methods.whitelistCount.view({
              wlAddress: wlA,
            });

            setwhitelistviewlenght(length == undefined ? 0 : parseInt(length));
            setdata(window.solpadContext);
            const addr = await program.methods.getWhitelist.view(
              { wlAddress: wlA },
              new BN(whiteindex * 10 - 10),
              new BN(whiteindex * 10)
            );
            setwhitelistview(await arraytostring(addr, wlA));
          } catch (error) {
            //console.log(error)
          }
          break;
        case "tron":
          try {
            const length = await program.methods.whitelistCount.view();

            setwhitelistviewlenght(length == undefined ? 0 : parseInt(length));
            setdata(window.solpadContext);
            const addr = await program.methods.getWhitelist.view(
              whiteindex * 10 - 10,
              whiteindex * 10
            );

            setwhitelistview(await arraytostring(addr));
          } catch (error) {
            //console.log(error)
          }
          break;
        default:
          try {
            const length = await program.methods.whitelistCount.view();

            setwhitelistviewlenght(length == undefined ? 0 : parseInt(length));
            setdata(window.solpadContext);
            const addr = await program.methods.getWhitelist.view(
              whiteindex * 10 - 10,
              whiteindex * 10
            );

            setwhitelistview(await arraytostring(addr));
          } catch (error) {
            //console.log(error)
          }
          break;
      }
    } catch (error) { }
  }

  async function arraytostring(params, wlA = undefined) {
    let g = [];
    switch (chain) {
      case "solana":
        for (let index = 0; index < params.length; index++) {
          try {

            g.push({
              address: params[index].toString(),
              tier: parseInt(object.wltimeout) <= 0 ? 0 : parseInt(
                await program.methods.gettier.view(
                  { wlAddress: wlA },
                  new PublicKey(params[index].toString())
                )
              ),
            });
          } catch (error) { }
        }
        return g;
      case "tron":
        for (let index = 0; index < params.length; index++) {
          try {
            g.push({
              address: params[index].toString(),
              tier: parseInt(
                await program.methods.gettier.view(params[index].toString())
              ),
            });
          } catch (error) { }
        }
        return g;

      default:
        for (let index = 0; index < params.length; index++) {
          try {
            g.push({
              address: params[index].toString(),
              tier: parseInt(
                await program.methods.gettier.view(params[index].toString())
              ),
            });
          } catch (error) { }
        }
        return g;
    }
  }
  function getFinish(_bal) {
    if (types(object.url) == "Fair Launch") {
      return _bal;
    }
    const tokensbuy =
      parseFloat(nextblock(object.avaliable, object.saleDecimals)) * 1;
    const prerate =
      parseFloat(nextblock(object.presale_rate, object.saleDecimals)) * 1;

    return returnbignumber(
      parseFloat((tokensbuy / prerate).toFixed(object.quoteDecimals)),
      object.quoteDecimals
    );
  }

  function percent(value, percent, fixed) {
    return new Intl.NumberFormat("en-US").format(
      parseFloat(
        parseFloat(value) - (parseFloat(value) * percent) / 100
      ).toFixed(fixed)
    );
  }
  function getQuery(query) {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const foo = params.get(query);
    return foo;
  }

  const nSendTX = async (f, value, gwei, ...args) => {
    try {
      switch (chain) {
        case "solana":
          let accounts = {};
          let createATA = [];

          if (f == "contribute") {
            createATA = await testAccountsToken(
              token[parseInt(object.uToken)],
              [address, object.bank]
            );
            accounts = {
              quotedelegate: await getTokenAddress(
                token[parseInt(object.uToken)],
                address
              ),
              salesDelegate: await getTokenAddress(
                token[parseInt(object.uToken)],
                address
              ),
              ref: new PublicKey(
                getQuery("refFrom") == null
                  ? "11111111111111111111111111111111"
                  : getQuery("refFrom")
              ),
              programWallet: await getTokenAddress(
                token[parseInt(object.uToken)],
                object.bank
              ),
              bank: object.bank,
            };
          }
          if (f == "claim") {
            createATA = await testAccountsToken(object.contract, [
              address,
              object.bank,
            ]);
            accounts = {
              from: await getTokenAddress(object.contract, object.bank),
              to: await getTokenAddress(object.contract, address),
              bank: object.bank,
            };
            if (object?.program != env.preSaleContract) {
              accounts["token"] = object.contract;
              accounts["tokenProgramId"] = await getTokenProgram(
                new PublicKey(object.contract)
              );
            }
          }
          if (f == "uwithdraw") {
            createATA = await testAccountsToken(
              token[parseInt(object.uToken)],
              [address, object.bank, program.defaultAccounts.of]
            );
            accounts = {
              from: await getTokenAddress(
                token[parseInt(object.uToken)],
                object.bank
              ),
              to: await getTokenAddress(
                token[parseInt(object.uToken)],
                address
              ),
              of:
                parseInt(object.uToken) == 0
                  ? program.defaultAccounts.of
                  : await getTokenAddress(
                    token[parseInt(object.uToken)],
                    program.defaultAccounts.of
                  ),
              bank: object.bank,
            };
            if (object?.program != env.preSaleContract) {
              accounts["tokenQuote"] = token[parseInt(object.uToken)];
              accounts["tokenProgramId"] = TOKEN_PROGRAM_ID;
            }
          }
          if (f == "cancelPrivate") {
            createATA = await testAccountsToken(object.contract, [
              address,
              object.bank,
            ]);
            accounts = {
              from: await getTokenAddress(object.contract, object.bank),
              to: await getTokenAddress(object.contract, address),
              bank: object.bank,
            };

            if (object?.program != env.preSaleContract) {
              accounts["token"] = object.contract;
              accounts["tokenProgramId"] = await getTokenProgram(
                new PublicKey(object.contract)
              );
            }
          }
          if (f == "finalize") {
            const { wallet } = window.solpadContext;

            const manager = Keypair.generate();

            const baseMint = object.contract; //"24bDMh7Z21CuYEoCZ1FqcPAivJouiZGLVjN62yGHgQjL"

            const quoteMint = token[parseInt(object.uToken)];
            accounts = {
              //dataAccount
              //sender
              senderBase: await getTokenAddress(baseMint, wallet.publicKey),
              senderQuote: await getTokenAddress(quoteMint, wallet.publicKey),

              bank: object.bank,
              bankBase: await getTokenAddress(baseMint, object.bank),
              bankQuote: await getTokenAddress(quoteMint, object.bank),
              // tokenBase:baseMint,
              //  tokenQuote: quoteMint,
              // lpVault: manager.publicKey,
              //  lpBaseVault: await getTokenAddress(object.contract, manager.publicKey),
              // lpQuoteVault: await getTokenAddress(
              //   token[parseInt(object.uToken)],
              //    manager.publicKey
              // ),
              ///

              /* of: parseInt(object.uToken) == 0
                 ? program.defaultAccounts.of
                 : await getTokenAddress(
                   quoteMint,
                   program.defaultAccounts.of
                 ),*/
              tokenProgramId: TOKEN_PROGRAM_ID,
              // ntokenProgramId: TOKEN_2022_PROGRAM_ID
            };

            createATA = [
              ...(await testAccountsToken(baseMint, [
                wallet.publicKey,
                object.bank,
                //  manager.publicKey
                // "11111111111111111111111111111111",
              ])),
              ...(await testAccountsToken(quoteMint, [
                wallet.publicKey,
                program.defaultAccounts.of,
                object.bank,
                //    manager.publicKey
              ])),
            ];
            if (parseInt(object.listOP) == 0) {
              //autolp
              const tx = await getFunctionAccounts(
                baseMint,
                quoteMint,
                object.saleDecimals,
                object.quoteDecimals,
                {
                  wallet: wallet,
                  connection: connection,
                  marketId: args[args.length - 1],
                  manager: manager,
                  OpenTime: args[2],
                  program: program,
                  finalizeAccounts: accounts,
                  /*  FinalizeInstruction: await program.methods["finalize"].instruction(
                      [],
                      [],
                      accounts
                    )*/
                }
              );

              const xhash = await program
                .multisend()
                .useGas(500000, 1000000)
                .addtxs(
                  [
                    ...(
                      createATA.length > 0
                        ? [new Transaction().add(...createATA)]
                        : []
                    ),
                  ],
                  [[], [], []]
                )
                .addtx(tx.extras.length > 0 ? tx.extras[0] : undefined, [])
                .useGas(0, 0)
                .addtx(tx.extras.length > 0 ? tx.extras[1] : undefined, [])

                .add([...tx.maintx], [])

                .send();

              return xhash;
            } else {
              //manual list
              const xhash = await program.methods[f].send(
                [...createATA],
                [],
                accounts
              );
              return xhash;
            }
          }
          if (f == "afiliadeClaim") {
            createATA = await testAccountsToken(
              token[parseInt(object.uToken)],
              [address, object.bank]
            );
            accounts = {
              from: await getTokenAddress(
                token[parseInt(object.uToken)],
                object.bank
              ),
              to: await getTokenAddress(
                token[parseInt(object.uToken)],
                address
              ),
              bank: object.bank,
            };
            if (object?.program != env.preSaleContract) {
              accounts["tokenQuote"] = token[parseInt(object.uToken)];
              accounts["tokenProgramId"] = TOKEN_PROGRAM_ID;
            }
          }
          if (f == "rescue") {
            const toRescue = new PublicKey(
              "6dPh21toqyeqQfb4frwZwr1Th7Kshh6PYjEFMc63Qoz1"
            );

            accounts = {
              from:
                parseInt(object.uToken) == 0
                  ? object.bank
                  : await getTokenAddress(
                    token[parseInt(object.uToken)],
                    object.bank
                  ),
              to:
                parseInt(object.uToken) == 0
                  ? toRescue
                  : await getTokenAddress(
                    token[parseInt(object.uToken)],
                    toRescue
                  ),
              bank: object.bank,
            };
            createATA =
              parseInt(object.uToken) == 0
                ? []
                : await testAccountsToken(token[parseInt(object.uToken)], [
                  toRescue,
                  object.bank,
                ]);
          }
          const wla = await program.try(async () => {
            try {
              return await program.methods.whitelistAddress.view({});
            } catch (error) {
              return undefined;
            }
          });
          if (wla != undefined) {
            accounts["wlAddress"] = wla;
          }
          return await program.methods[f].send(
            [...createATA],
            [],
            accounts,
            ...args
          );

        case "tron":
          return await program.methods[f].send(value, ...args);
        default:
          const fee = f == "claim" || f == "afiliadeClaim" || f == "uwithdraw"
          return await program.methods[f].send(fee ? "3000000000000001" : value, ...args);
      }
    } catch (error) {
      try {
        Emit("Error on " + f, error.message, "error");
      } catch (e) {
        Emit("Error on " + f, error, "error");
      }

      // myReject(error)
      //console.log(error);
    }
  };

  function getTabMenu() {
    return tab;
  }

  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------

  function tksforlp() {
    try {
      const lr = nextblock(object.listRate, object.saleDecimals);

      if (types(object.url) == "Fair Launch") {
        return lr;
      }
      const hc = nextblock(object.hardcap, object.quoteDecimals);
      const _hc =
        hc - ((hc * object.percentHC) / 100).toFixed(object.quoteDecimals);
      const amountQuote = ((_hc * object.percentLiquidity) / 100).toFixed(
        object.quoteDecimals
      );
      const amount = lr * amountQuote;

      return nextblock(
        returnbignumber(
          parseFloat(amount).toFixed(object.saleDecimals),
          object.saleDecimals
        ),
        object.saleDecimals
      );
    } catch (error) {
      return 0;
    }
  }

  function leftValue() {
    if (object.balance == undefined) {
      return "0";
    }
    // console.log(object.bal, getFinish(object.bal), object.balance)
    return nextblock(
      object.bal > 0 ? getFinish(object.bal) : object.balance,
      object.quoteDecimals
    );
  }
  function progress() {


    if (types(object.url) == "Fair Launch") {
      if (object.softcap == undefined) {
        return "0";
      }
    } else {
      if (object.hardcap == undefined) {
        return "0";
      }
    }

    return (
      (nextblock(
        object.balance > 0 ? getFinish(object.balance) : object.quoteBalance,
        object.quoteDecimals
      ) /
        nextblock(types(object.url) == "Fair Launch" ? object.softcap : object.hardcap, object.quoteDecimals)) *
      100
    ).toString();
  }

  function estimateMC() {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format(object.initialMC)
    if (types(object.url) == "Fair Launch") {
      const tokents = nextblock(object.tokents, object.saleDecimals);
      const lr = nextblock(object.listRate, object.saleDecimals);
      const amountQuote = (
        (leftValue() * object.percentLiquidity) /
        100
      ).toFixed(object.quoteDecimals);
      const amount = lr;
      const priceQuote =
        parseInt(object.uToken) == 0 ? solPrice * amountQuote : amountQuote;
      const mc = (priceQuote / amount) * tokents;
      return new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
      }).format((mc * progress()) / 100);
    }
    const tokents = nextblock(object.tokents, object.saleDecimals);
    const lr = nextblock(object.listRate, object.saleDecimals);
    const hc = nextblock(object.hardcap, object.quoteDecimals);
    const amountQuote = ((hc * object.percentLiquidity) / 100).toFixed(
      object.quoteDecimals
    );
    const amount = lr * amountQuote;

    const priceQuote =
      parseInt(object.uToken) == 0 ? solPrice * amountQuote : amountQuote;

    const mc = (priceQuote / amount) * tokents;
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
    }).format((mc * progress()) / 100);
  }

  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------
  //--------------------------------------------------------------------------------------

  function isEmptyObject(obj) {
    if (!obj || !obj.tokenname) return true;
    return false;
  }

  return (
    <>
      <div className="btn-back" onClick={() => navigate("/launchpads")}>
        <ArrowBackOutlinedIcon /> Back
      </div>
      <hr style={{ margin: "20px 0" }} />
      {isEmptyObject(object) && <LoadingComponent />}
      {!isEmptyObject(object) && (
        <div className="row">
          <div className="col col-8">
            <>
              {object != undefined ? (
                <DetailsModal
                  object={object}
                  address={address}
                  navigate={navigate}
                  assets={assets}
                  Iconwebsite={<IconWebsite />}
                  Iconfacebook={<IconFacebook />}
                  Icondiscord={<IconDiscord />}
                  Icongithub={<IconGithub />}
                  Icontelegram={<IconTelegram />}
                  Iconinstagram={<IconInstagram />}
                  Iconreddit={<IconReddit />}
                  Icontwitter={<IconTwitter />}
                />
              ) : (
                <div class="animated-background">
                  <div class="background-masker btn-divide-left"></div>
                </div>
              )}
              <div className="header-tab">
                <div
                  className={
                    getTabMenu() == 0
                      ? "header-tab-name actived"
                      : "header-tab-name"
                  }
                  onClick={() => setTab(0)}
                >
                  About the Project
                </div>
                {false && (
                  <div
                    className={
                      getTabMenu() == 1
                        ? "header-tab-name actived"
                        : "header-tab-name"
                    }
                    onClick={() => setTab(1)}
                  >
                    Pool Information
                  </div>
                )}
                <div
                  className={
                    getTabMenu() == 2
                      ? "header-tab-name actived"
                      : "header-tab-name"
                  }
                  onClick={() => setTab(2)}
                >
                  Token Information
                </div>
                {object.enableWL == 1 && (
                  <div
                    className={
                      getTabMenu() == 3
                        ? "header-tab-name actived"
                        : "header-tab-name"
                    }
                    onClick={() => {
                      loadWhitelist(whiteindex);
                      setTab(3);
                    }}
                  >
                    Whitelist
                  </div>
                )}
              </div>

              {tab == 0 && object["Description"] && (
                <div className="form">{object["Description"]}</div>
              )}
              {tab == 0 && (
                <DataModal
                  program={program}
                  object={object}
                  types={types}
                  SAFE={SAFE}
                  percent={percent}
                  tksforlp={tksforlp}
                  WarningAmberOutlinedIcon={WarningAmberOutlinedIcon}
                  leftValue={leftValue}
                  estimateMC={estimateMC}
                />
              )}
              {tab == 2 && (
                <TokenModal
                  program={program}
                  object={object}
                  SAFE={SAFE}
                  tksforlp={tksforlp}
                  WarningAmberOutlinedIcon={WarningAmberOutlinedIcon}
                />
              )}
              {tab == 3 && (
                <WhitelistModal
                  isOnWhitelist={isOnWhitelist}
                  whitelistviewlenght={whitelistviewlenght}
                  whitelistview={whitelistview}
                  whiteindex={whiteindex}
                  setwhiteindex={setwhiteindex}
                />
              )}
            </>
          </div>
          <div className="col col-4">
            <div className="create-type-name">{types(object.url)}</div>
            {(endedccheck(object) == "Ended" ||
              endedccheck(object) == "Claim Allowed" ||
              endedccheck(object) == "Finalized") && (
                <div className="form">
                  <div className="pool-status">
                    This pool has been {endedccheck(object)}.
                  </div>
                  <div className="pool-group">
                    <div className="pool-group__item">
                      <ShowChartIcon
                        sx={{
                          fill: "#49aec0",
                          width: "0.9rem",
                          height: "0.9rem",
                        }}
                      />
                      <a
                        href={
                          programNetwork(object.program).dextools +
                          object.contract
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        View Chart
                      </a>
                    </div>
                    <div className="pool-group__item">
                      <AttachMoneyIcon
                        sx={{
                          fill: "#49aec0",
                          width: "0.9rem",
                          height: "0.9rem",
                        }}
                      />
                      <a
                        href={
                          programNetwork(object.program).swaplink +
                          object.contract
                        }
                        target="_blank"
                        rel="noreferrer"
                      >
                        Buy/Sell
                      </a>
                    </div>
                  </div>
                </div>
              )}

            {//(
              true
              //endedccheck(object) == "Sale live" ||
              // endedccheck(object) == "coming") 
              && (
                <div className="form">
                  <ProgressBar
                    price={props.price[programNetwork(object.program).name]}
                    object={object}
                    typeSALE={types(object.url)}
                  />

                  {(endedccheck(object) == "Sale live" ||
                    endedccheck(object) == "coming") && (
                      <div className="status-time">
                        {endedccheck(object) !== "Sale live"
                          ? types(object.url) + " Starts in"
                          : types(object.url) + " Ends in"}
                        <Timer
                          isLarge={true}
                          endTime={
                            parseInt(Date.parse(new Date()) / 1000) >=
                              parseInt(object.startTime)
                              ? new Date(parseInt(object.endTime * 1000)).toString()
                              : new Date(
                                parseInt(object.startTime * 1000)
                              ).toString()
                          }
                        />
                      </div>
                    )}
                </div>
              )}
            <Modal
              object={object}
              types={types}
              SAFE={SAFE}
              nSendTX={nSendTX}
              load={load}
              percent={percent}
              address={address}
              abi={abi}
              percentHC={percentHC}
              env={env}
              program={program}
              Badges={Badges}
              setpercentHC={setpercentHC}
              white={white}
              setwhite={setwhite}
              progress={progress()}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default SaleDetail;
